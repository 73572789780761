import { formatterPeso } from "@enerbit/base";

//TODO: Esta funcion tiene que ir para el base

// Función para calcular el valor del IVA
export const calculateValueIva = (
    valueWithoutIva: number,
    ivaPercentage: number = 19,
): number => {
    const valueWithIva = valueWithoutIva * (ivaPercentage / 100);
    return valueWithIva;
};

// Función para formatear el valor del IVA para una tabla
export const calculateValueIvaTable = (valueWithoutIva: number): string =>
    formatterPeso.format(calculateValueIva(valueWithoutIva));

// Función para calcular el valor total de una fila
export const calculateValueTotalRow = (valueWithoutIva: any): any => {
    const valueTotalRow = formatterPeso.format(
        calculateValueIva(valueWithoutIva),
    );
    return valueTotalRow;
};

// Función para calcular el total del valor para el cargo mensual fijo
export const calculateFixedMonthlyChargeValue = (
    arrayExternal: any[],
): string => {
    let totalValueFixedMonthlyCharge = 0;

    arrayExternal.forEach((obj) => {
        if (obj.conditions && obj.conditions.components) {
            obj.conditions.components.forEach(
                (component: { price: number }) => {
                    totalValueFixedMonthlyCharge += component.price;
                },
            );
        }
    });

    return formatterPeso.format(totalValueFixedMonthlyCharge);
};

// Función para calcular el total del IVA del cargo mensual fijo
export const calculateIvaFromColumnValue = (arrayExternal: any[]): string => {
    let totalIvaFixedMonthlyCharge = 0;
    arrayExternal.forEach((obj) => {
        if (obj.conditions && obj.conditions.components) {
            obj.conditions.components.forEach(
                (component: { price: number }) => {
                    const valueWithIva = calculateValueIva(component.price);
                    totalIvaFixedMonthlyCharge += valueWithIva;
                },
            );
        }
    });
    return formatterPeso.format(totalIvaFixedMonthlyCharge);
};

// Función para calcular el total del cargo mensual fijo
export const calculateTotalMonthlyCharge = (arrayExternal: any[]): string => {
    let totalMonthlyFixedCharge = 0;

    arrayExternal.forEach((obj) => {
        if (obj.conditions && obj.conditions.components) {
            obj.conditions.components.forEach(
                (component: { price: number }) => {
                    const originalPrice = component.price;
                    const priceWithIva = calculateValueIva(originalPrice);
                    const totalPriceWithIva = originalPrice + priceWithIva;
                    totalMonthlyFixedCharge += totalPriceWithIva;
                },
            );
        }
    });

    return formatterPeso.format(totalMonthlyFixedCharge);
};
