import {
    Box,
    Grid,
    Typography,
    enerbitColors,
    formatterPeso,
} from "@enerbit/base";
import {
    calculateFixedMonthlyChargeValue,
    calculateIvaFromColumnValue,
    calculateTotalMonthlyCharge,
    calculateValueIvaTable,
    calculateValueTotalRow,
} from "../helpers/CalculateMonthlyFixedCharge";

export const MonthlyFixedChargeTable = (arrayExternal: any) => {
    return (
        <>
            {/* //TODO: aqui va la nueva tabla de implementacion */}
            <Box
                sx={{
                    width: "100%",
                    borderBottom: 1,
                    backgroundColor: "transparent",
                    borderColor: "#D8D8D8",
                    mt: "20px",
                    paddingBottom: "0.3rem",
                    display: "flex",
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            color={enerbitColors.primary.main}
                        >
                            Concepto
                        </Typography>
                        <Typography
                            variant="body1"
                            color={enerbitColors.primary.main}
                        >
                            {arrayExternal.map((element: any) => {
                                if (element) {
                                    return "";
                                }
                                return;
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            color={enerbitColors.primary.main}
                        >
                            Valor
                        </Typography>
                        {/* <Box>
              {arrayExternal.map(
                (
                  charge: Key | null | undefined,
                  index: Key | null | undefined
                ) => {
                  if (charge) {
                    return <Fragment key={index}></Fragment>;
                  }
                  return (
                    <Box key={charge}>
                      {arrayExternal.map(() => {
                        return (
                          <>
                            <ItemInterval
                              interval={{
                                start: "string",
                                duration: "string",
                                price: 0,
                              }}
                              colorText={enerbitColors.primary.main}
                            />

                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color={enerbitColors.primary.main}
                            >
                              que es esto
                            </Typography>
                          </>
                        );
                      })}
                    </Box>
                  );
                }
              )}
            </Box> */}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            color={enerbitColors.primary.main}
                        >
                            IVA 19%
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            color={enerbitColors.primary.main}
                        >
                            Total
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <>
                {arrayExternal.map(
                    (
                        obj: {
                            conditions: {
                                price: number;
                                components: any[];
                                intervals: any[];
                            };
                        },
                        index: number,
                    ) => {
                        if (obj.conditions && obj.conditions.components) {
                            return obj.conditions.components.map(
                                (component, compIndex) => {
                                    return (
                                        <Box
                                            sx={{
                                                width: "100%",
                                                borderBottom: 1,
                                                backgroundColor: "transparent",
                                                borderColor: "#D8D8D8",
                                                mt: "1rem",
                                                display: "flex",
                                            }}
                                            key={`${index}-${compIndex}`}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding:
                                                            "0.3rem 1rem !important",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        color={
                                                            enerbitColors
                                                                .neutral[700]
                                                        }
                                                    >
                                                        {component.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        paddingTop:
                                                            "0rem !important",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        color={
                                                            enerbitColors
                                                                .neutral[700]
                                                        }
                                                    >
                                                        {formatterPeso.format(
                                                            component.price,
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        paddingTop:
                                                            "0rem !important",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        color={
                                                            enerbitColors
                                                                .neutral[700]
                                                        }
                                                    >
                                                        {calculateValueIvaTable(
                                                            component.price,
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        paddingTop:
                                                            "0rem !important",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        color={
                                                            enerbitColors
                                                                .neutral[700]
                                                        }
                                                    >
                                                        {calculateValueTotalRow(
                                                            component.price,
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    );
                                },
                            );
                        }
                        // if (obj.conditions && obj.conditions.intervals) {
                        //   obj.conditions.intervals.map((interval) => {
                        //      console.log(interval.start);
                        //     console.log(interval.duration);
                        //     console.log(interval.price);
                        //   });
                        // }
                    },
                )}
            </>
            <Box
                sx={{
                    width: "100%",
                    backgroundColor: "transparent",
                    margin: "1.1rem 0 1.5rem 0",
                    paddingBottom: "0.3rem",
                    display: "flex",
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            color={enerbitColors.primary.main}
                        >
                            Cargo fijo mensual
                        </Typography>
                        <Typography
                            variant="body1"
                            color={enerbitColors.primary.main}
                        >
                            {/* {arrayExternal.map((element) => {
                  if (element) {
                    return "";
                  }
                  return formatterPeso.format(150 ?? 0);
                })} */}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <>
                            <Typography
                                variant="body1"
                                fontWeight="bold"
                                color={enerbitColors.primary.main}
                            >
                                {calculateFixedMonthlyChargeValue(
                                    arrayExternal,
                                )}
                            </Typography>
                        </>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            color={enerbitColors.primary.main}
                        >
                            {calculateIvaFromColumnValue(arrayExternal)}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            color={enerbitColors.primary.main}
                        >
                            {calculateTotalMonthlyCharge(arrayExternal)}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
